<template>
	<div v-if="!loading">
		<div v-if="formData.locked">
			<b-alert variant="secondary" show>
				<div class="alert-body d-flex align-items-center py-1">
					<div class="mr-1">
						<feather-icon icon="AlertTriangleIcon" size="24" class="mr-1" />
						<span>This form is locked you can't delete any field</span>
					</div>
				</div>
			</b-alert>
		</div>
		<b-row>
			<b-col cols="12" md="4">
				<b-card no-body>
					<div class="p-2">
						<b-card-title>
							<div class="d-flex justify-content-between align-items-center">
								<span>{{ formData.name }}</span>

								<b-button v-can="'formsBuilder.edit'" variant="primary" @click="saveForm">
									Save form
								</b-button>
							</div>
						</b-card-title>
						<b-card-text>{{ formData.description }}</b-card-text>
					</div>
					<hr />
					<div class="d-flex justify-content-between px-2 align-items-center">
						<span class="h5 mb-0">Form pages</span>
						<new-page v-can="'formsBuilder.edit'" :data="pageToEdit" @hidden="pageToEdit = undefined"></new-page>
					</div>

					<b-list-group flush class="mt-1">
						<orderable-list v-model="formData.pages">
							<b-list-group-item
								v-for="page in formData.pages"
								:key="page.id"
								button
								:active="currentPage.id === page.id"
								class="px-2 py-1 d-flex justify-content-between"
								@click="selectedPage = page.id"
							>
								<feather-icon icon="MenuIcon" class="drag-handle" />
								<span>{{ page.name }}</span>
								<span v-can="'formsBuilder.edit'">
									<template v-if="!formData.locked">
										<feather-icon
											v-if="formData.pages.length > 1"
											icon="Trash2Icon"
											size="16"
											@click.stop="deletePage(page.id)"
										/>
									</template>

									<feather-icon
										v-b-modal.new-page-modal
										class="ml-1"
										icon="Edit3Icon"
										size="16"
										@click="editPage(page)"
									/>
								</span>
							</b-list-group-item>
						</orderable-list>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col cols="12" md="8">
				<b-card>
					<div class="d-flex justify-content-between align-items-center">
						<span class="h5 mb-0">Page fields</span>
						<new-field
							v-can="'formsBuilder.edit'"
							:form-id="formData.id"
							:page-id="currentPage.id"
							:is-dynamic="isDynamic"
							@submitted="saveField"
							@hidden="isDynamic = false"
						></new-field>
					</div>
					<div class="mt-2">
						<page-fields
							:page-data="{ page: currentPage, formId: formData.id, form_is_locked: formData.locked }"
							@addDynamic="addDynamic"
							@skipTo="skipTo"
						/>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<confirm-dailog ref="confirm"></confirm-dailog>
	</div>
</template>

<script>
import store from '@/store';
import { BCard, BCardTitle, BButton, BCardText, BAlert, BRow, BCol, BListGroup, BListGroupItem, VBTooltip, VBModal } from 'bootstrap-vue';
import { required } from '@validations';
import ConfirmDailog from '@/components/ConfirmDailog.vue';
import OrderableList from '@/components/OrderableList.vue';
import NewPage from './new-page.vue';
import NewField from './new-field.vue';
import PageFields from './page-fields.vue';

export default {
	name: 'FormDetails',

	components: {
		BCard,
		BCardTitle,
		BCardText,
		BButton,
		BRow,
		BCol,
		BListGroup,
		BListGroupItem,
		BAlert,
		ConfirmDailog,
		OrderableList,
		NewPage,
		NewField,
		PageFields,
	},

	directives: {
		'b-tooltip': VBTooltip,
		'b-modal': VBModal,
	},

	props: {
		id: {
			type: [String, Number],
			required: true,
		},
	},

	data: () => ({
		formData: null,
		selectedPage: 0,
		dynamicId: 0,
		currentField: undefined,
		pageToEdit: undefined,
		loading: false,
		isDynamic: false,

		skipFieldId: 0,
		skipOptionId: 0,

		required,
	}),

	computed: {
		currentPage() {
			return this.selectedPage ? this.formData.pages.find((page) => page.id === this.selectedPage) : this.formData.pages[0];
		},
	},

	async created() {
		this.loading = true;
		try {
			this.formData = await store.dispatch('forms/getForm', { formId: this.id, noLoading: false });
		} catch (error) {
			console.log(error);
		}

		this.loading = false;
	},

	methods: {
		editPage(page) {
			this.pageToEdit = page;
		},

		async saveForm() {
			this.formData = await store.dispatch('forms/save');
		},

		addDynamic({ isDynamic, id }) {
			this.isDynamic = isDynamic;
			this.dynamicId = id;
		},

		skipTo({ fieldId, optionId }) {
			this.skipFieldId = fieldId;
			this.skipOptionId = optionId;
		},

		saveField(data) {
			if (this.isDynamic) {
				store.dispatch('forms/addToDynamicField', {
					...data,
					fieldId: this.dynamicId,
				});

				return;
			}

			if (this.skipFieldId !== 0) {
				store.dispatch('forms/addSkipToField', {
					...data,
					fieldId: this.skipFieldId,
					optionId: this.skipOptionId,
				});

				this.reset();
				return;
			}

			store.dispatch('forms/addField', data);
		},

		async deletePage(pageId) {
			const confirm = await this.$refs.confirm.open('Delete page', 'Are you sure, delete this page?');
			if (confirm) {
				if (this.selectedPage === pageId) this.selectedPage = 0;
				await store.dispatch('forms/deletePage', { pageId });
			}
		},

		reset() {
			this.skipFieldId = 0;
			this.skipOptionId = 0;
		},
	},
};
</script>

<style lang="scss">
.drag-handle {
	cursor: move;
}
</style>
