<template>
	<div class="">
		<b-button v-b-modal.new-page-modal variant="outline-primary" size="sm">
			<feather-icon icon="PlusIcon" class="mr-50" />
			<span class="align-middle">Add Page</span>
		</b-button>
		<b-modal
			id="new-page-modal"
			ref="newPageModal"
			:title="isEdit ? 'Edit page' : 'Add new page'"
			ok-name="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="$emit('hidden')"
			@ok="handleOk"
		>
			<validation-observer ref="simpleRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-input id="name" v-model="formData.name" :state="errors.length > 0 ? false : null" placeholder="Name" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- description -->
						<b-col cols="12">
							<b-form-group label="Description" label-for="description">
								<b-form-textarea id="description" v-model="formData.description" type="text" placeholder="Description" />
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea } from 'bootstrap-vue';
import { required } from '@validations';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		BFormTextarea,
	},
	props: {
		data: {
			type: Object,
			default: () => ({
				description: '',
				name: '',
			}),
		},
	},
	data() {
		return {
			required,
			formData: null,
		};
	},

	computed: {
		isEdit() {
			return this.data.name !== '';
		},
	},

	watch: {
		data: {
			handler(val) {
				this.formData = { ...val };
			},
			deep: true,
			immediate: true,
		},
	},

	methods: {
		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},
		async handleSubmit() {
			const success = await this.$refs.simpleRules.validate();
			if (!success) return;

			if (!this.isEdit) {
				store.dispatch('forms/addPage', {
					pageData: {
						name: this.formData.name,
						description: this.formData.description,
					},
				});
			} else {
				store.dispatch('forms/editPage', {
					pageData: {
						...this.data,
						name: this.formData.name,
						description: this.formData.description,
					},
				});
			}

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newPageModal.toggle('#toggle-btn');
			});

			this.$emit('done');

			this.formData.name = '';
			this.formData.description = '';
		},
	},
};
</script>

<style lang="scss" scoped></style>
