<template>
	<b-modal v-model="dailog" :title="title" button-size="sm" size="sm" :ok-title="okText" @keydown.esc="close" @ok="agree" @cancel="close">
		<b-card-text v-show="!!message">{{ message }}</b-card-text>
	</b-modal>
</template>

<script>
import { BModal, VBModal, BCardText } from 'bootstrap-vue';

export default {
	name: 'ConfirmDialog',

	components: {
		BCardText,
		BModal,
	},

	directives: {
		'b-modal': VBModal,
	},

	props: {
		okText: {
			type: String,
			default: 'OK',
		},
	},
	data: () => ({
		confirm: null,
		dailog: false,
		cancel: null,
		message: null,
		title: null,
	}),
	methods: {
		open(title, message) {
			this.dailog = true;
			this.title = title;
			this.message = message;
			return new Promise((resolve, reject) => {
				this.confirm = resolve;
				this.cancel = reject;
			});
		},
		agree() {
			this.confirm(true);
			this.dailog = false;
		},
		close() {
			this.confirm(false);
			this.dailog = false;
		},
	},
};
</script>

<style lang="scss" scoped></style>
