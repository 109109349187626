<template>
	<div>
		<validation-observer ref="simpleRules">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Question" label-for="question">
							<validation-provider #default="{ errors }" name="Question" rules="required">
								<b-form-input
									id="question"
									v-model="formData.question"
									:state="errors.length > 0 ? false : null"
									placeholder="Question"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<div class="d-flex justify-content-between">
							<label class="mb-50">Options</label>
							<b-form-checkbox v-model="value.yesOrNo" class="mb-1" switch inline>
								Yes OR No
							</b-form-checkbox>
						</div>
						<b-card border-variant="secondary" class="shadow-none p-1" no-body>
							<div v-for="option in options" :key="option.id" class="d-flex align-items-center mb-1">
								<feather-icon icon="MoreVerticalIcon" class="draggable-handle mr-50 cursor-pointer" />

								<b-form-group class="mb-0 flex-grow-1">
									<b-form-input v-model="option.answer" type="text" placeholder="Option" />
								</b-form-group>
								<b-form-group class="mb-0 flex-grow-1 ml-75">
									<b-form-input v-model="option.value" type="text" placeholder="Value" />
								</b-form-group>
								<b-form-checkbox v-model="option.alert" class="mx-1">Alert</b-form-checkbox>

								<b-button
									v-b-tooltip.hover.top="'Delete'"
									:disabled="options.length === 1"
									variant="outline-secondary"
									class="btn-icon cursor-pointer"
									size="sm"
									@click="deleteOption(option)"
								>
									<feather-icon icon="Trash2Icon" size="16" />
								</b-button>
							</div>
							<div class="d-flex justify-content-start">
								<b-button size="sm" variant="outline-primary" @click="addOption">
									<feather-icon icon="PlusIcon" class="mr-50" />
									<span class="align-middle">Add Option</span>
								</b-button>
							</div>
						</b-card>
					</b-col>

					<b-col cols="12">
						<b-form-group label="Help" label-for="help">
							<b-form-textarea id="help" v-model="formData.help" type="text" placeholder="Help" />
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-checkbox v-model="formData.is_required" class="mb-1">Make this field required</b-form-checkbox>
						<b-form-checkbox v-model="formData.is_multiple" :disabled="formData.is_dropdown"
							>Allow multiple selection</b-form-checkbox
						>
					</b-col>
					<b-col cols="6">
						<b-form-checkbox v-model="formData.is_dropdown" class="mb-1">Show as drop down list</b-form-checkbox>
						<b-form-checkbox v-model="formData.allow_other">Allow select other</b-form-checkbox>
					</b-col>

					<b-col>
						<hr class="mt-3 mb-50" />
						<span class="h5">Preview</span>
					</b-col>

					<b-col cols="12" class="mt-2">
						<list-field-preview :field-data="value"></list-field-preview>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import { BCard, BButton, BFormCheckbox, VBTooltip } from 'bootstrap-vue';
import { generateId, getOrder } from '@/utils/helpers';
import FieldMixin from './field-mixin.js';
import ListFieldPreview from '../preview/list-field-preview.vue';

export default {
	name: 'ListField',

	components: {
		BCard,
		BButton,
		BFormCheckbox,
		ListFieldPreview,
	},

	directives: {
		'b-tooltip': VBTooltip,
	},

	mixins: [FieldMixin],

	props: {
		value: {
			type: Object,
			default: () => ({
				question: '',
				help: '',
				is_required: true,
				is_multiple: false,
				is_dropdown: false,
				allow_other: false,
				yesOrNo: false,
				options: [
					{
						answer: 'Option 1',
						alert: false,
						form_value: null,
						order: 1,
						id: generateId(),
						skipPages: null,
					},
				],
			}),
		},
	},

	data: () => ({
		options: [],
	}),

	watch: {
		value: {
			handler(val) {
				if (val) {
					this.options = val.options;
				}

				if (val.is_dropdown) {
					this.value.is_multiple = false;
				}
			},
			immediate: true,
			deep: true,
		},
		'value.yesOrNo': {
			handler(val) {
				this.options = [];

				if (val) {
					this.addOption('نعم');
					this.addOption('لا');
				} else {
					this.addOption('Option 1');
				}
			},
		},
	},

	methods: {
		addOption(answer = '', value = null) {
			this.options.push({
				answer: typeof answer === 'string' ? answer : '',
				alert: false,
				form_value: value,
				order: getOrder(this.options),
				id: generateId(),
				skipPages: null,
			});

			this.formData.options = this.options;
		},

		deleteOption(option) {
			this.options = this.options.filter((o) => o.id !== option.id);
			this.formData.options = this.options;
		},
	},
};
</script>

<style lang="scss" scoped>
.custom-checkbox.custom-control,
.custom-radio.custom-control {
	margin-bottom: 0;
}
</style>
