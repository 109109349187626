<template>
	<div>
		<b-form-group>
			<template #label>
				<div class="">
					<p class="mb-0">{{ fieldData.question }} <span v-show="fieldData.is_required" class="text-danger"> *</span></p>
				</div>
			</template>
			<b-form-radio-group
				v-if="!fieldData.is_multiple && !fieldData.is_dropdown"
				:options="fieldData.options"
				stacked
				value-field="id"
				text-field="answer"
			/>
			<b-form-checkbox-group
				v-else-if="fieldData.is_multiple && !fieldData.is_dropdown"
				:options="fieldData.options"
				value-field="id"
				text-field="answer"
				class="mb-1"
				stacked
			/>
			<v-select
				v-if="fieldData.is_dropdown && !otherSelected"
				v-model="selected"
				:options="selectOptions"
				:multiple="fieldData.is_multiple && !fieldData.allow_other"
			/>
			<b-form-input v-if="fieldData.is_dropdown && fieldData.allow_other && otherSelected" placeholder="Other.."></b-form-input>
			<small v-show="fieldData.help">{{ fieldData.help }}</small>
		</b-form-group>
		<div v-if="showExtra && hasSkip" class="">
			<div v-for="(option, i) in optionsWithSkip" :key="i">
				<p><feather-icon icon="CornerDownRightIcon" size="16" /> If ({{ option.answer }}) show these questions</p>
				<div class="ml-2">
					<orderable-list v-model="option.skipPages.fields.pages[0].fields">
						<b-card
							v-for="field in option.skipPages.fields.pages[0].fields"
							:key="field.id"
							border-variant="secondary"
							class="shadow-none"
							no-body
						>
							<div class="d-flex">
								<div class="p-1 flex-grow-1">
									<component :is="`${field.type}Preview`" :field-data="field" />
								</div>
								<div v-can="'formsBuilder.edit'" class="p-50 d-flex  align-items-center field-actions">
									<div class="d-flex flex-column">
										<b-button variant="flat-secondary" class="btn-icon mb-1" size="sm">
											<feather-icon icon="MenuIcon" class="drag-handle" />
										</b-button>

										<b-button
											variant="flat-secondary"
											class="btn-icon cursor-pointer mb-1"
											size="sm"
											@click="deleteField(field)"
										>
											<feather-icon icon="Trash2Icon" size="16" />
										</b-button>

										<b-button
											v-b-modal.edit-field-modal
											variant="flat-secondary"
											class="btn-icon cursor-pointer mb-1"
											size="sm"
											@click="editField(field)"
										>
											<feather-icon icon="Edit3Icon" size="16" />
										</b-button>

										<b-button
											v-if="field.type === 'DynamicField'"
											v-b-tooltip.hover.top="'Add field'"
											variant="flat-secondary"
											class="btn-icon cursor-pointer"
											size="sm"
										>
											<feather-icon icon="PlusIcon" size="16" />
										</b-button>
									</div>
								</div>
							</div>
						</b-card>
					</orderable-list>
				</div>
			</div>
		</div>
		<confirm-dailog ref="confirm"></confirm-dailog>
	</div>
</template>

<script>
import store from '@/store';
import { BFormGroup, BFormInput, BButton, BFormRadioGroup, BFormCheckboxGroup, BCard, VBTooltip, VBModal } from 'bootstrap-vue';
import vSelect from 'vue-select';
import ConfirmDailog from '@/components/ConfirmDailog';
import OrderableList from '@/components/OrderableList.vue';

/* eslint-disable vue/no-unused-components */
import TextFieldPreview from './text-field-preview';
import LargeTextFieldPreview from './large-text-field-preview';
import TableTextFieldPreview from './table-list-field-preview';
import DateFieldPreview from './date-field-preview';
import ImageFieldPreview from './image-field-preview';
import DynamicFieldPreview from './dynamic-field-preview';

export default {
	name: 'ListFieldPreview',

	components: {
		BFormGroup,
		BFormRadioGroup,
		BFormCheckboxGroup,
		BFormInput,
		BButton,
		BCard,

		TextFieldPreview,
		LargeTextFieldPreview,
		TableTextFieldPreview,
		DateFieldPreview,
		ImageFieldPreview,
		DynamicFieldPreview,

		vSelect,
		ConfirmDailog,
		OrderableList,
	},

	directives: {
		'b-tooltip': VBTooltip,
		'b-modal': VBModal,
	},

	props: {
		fieldData: {
			type: Object,
			default: () => ({
				question: '',
				max: '',
				min: '',
				help: '',
				is_required: false,
				is_multiple: false,
				is_dropdown: false,
				allow_other: false,
				options: [],
			}),
		},

		showExtra: Boolean,
	},

	data: () => ({
		selected: '',
	}),

	computed: {
		selectOptions() {
			return this.fieldData.options.map((o) => o.answer);
		},
		otherSelected() {
			return this.selected === 'Other';
		},
		optionsWithSkip() {
			return this.fieldData.options.filter((o) => !!o.skipPages);
		},
		hasSkip() {
			return this.optionsWithSkip.length >= 0;
		},
	},

	watch: {
		fieldData: {
			handler() {
				this.selected = '';
			},
			deep: true,
			immediate: true,
		},
	},

	methods: {
		async deleteField(field) {
			const confirm = await this.$refs.confirm.open('Delete filed', 'Are you sure, delete this filed?');
			if (confirm) {
				await store.dispatch('forms/deleteField', { fieldId: field.id, fieldType: field.type, parentFieldId: this.fieldData.id });
			}
		},

		editField(field) {
			this.$emit('editfield', field);
		},
	},
};
</script>

<style lang="scss">
.field-actions {
	border-left: 1px solid #82868b !important;
}

.custom-checkbox.custom-control,
.custom-radio.custom-control {
	margin-bottom: 0.6rem;
}
</style>
