/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea, BFormCheckbox } from 'bootstrap-vue';
import { required } from '@validations';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BFormTextarea,
		BFormCheckbox,
	},

	data: () => ({
		required,
	}),

	computed: {
		formData() {
			return this.value;
		},
	},

	watch: {
		formData: {
			async handler(val) {
				if (val.min) val.min = parseInt(val.min, 10);
				if (val.max) val.max = parseInt(val.max, 10);

				Vue.set(val, 'isValid', await this.$refs.simpleRules.validate());
				this.$emit('input', val);
			},
			deep: true,
		},
	},
};
