<template>
	<div>
		<template v-if="pageData.page.fields.length">
			<orderable-list :key="pageData.page.id" v-model="pageData.page.fields" is-parent>
				<b-card v-for="field in pageData.page.fields" :key="field.id" border-variant="secondary" class="shadow-none" no-body>
					<div class="d-flex">
						<div class="p-1 flex-grow-1">
							<field-preview show-extra :field-data="field" @editfield="editField($event)" />
						</div>
						<div v-can="'formsBuilder.edit'" class="p-1 d-flex align-items-center field-actions">
							<div class="d-flex flex-column">
								<b-button variant="flat-secondary" class="btn-icon mb-1" size="sm">
									<feather-icon icon="MenuIcon" class="drag-handle" />
								</b-button>
								<b-button
									v-if="field.type === 'DynamicField'"
									v-b-modal.new-field-modal
									variant="outline-secondary"
									class="btn-icon cursor-pointer mb-1"
									size="sm"
									@click="addDynamic(field)"
								>
									<feather-icon icon="PlusIcon" size="16" />
								</b-button>

								<template v-if="canDeleteField(field)">
									<b-button
										variant="outline-secondary"
										class="btn-icon cursor-pointer"
										size="sm"
										@click="deleteField(field)"
									>
										<feather-icon icon="Trash2Icon" size="16" />
									</b-button>
								</template>

								<b-button
									v-b-modal.edit-field-modal
									variant="outline-secondary"
									class="btn-icon mt-1 cursor-pointer"
									size="sm"
									@click="editField(field)"
								>
									<feather-icon icon="Edit3Icon" size="16" />
								</b-button>

								<b-button
									v-if="field.type === 'ListField'"
									v-b-modal.skip-to-modal
									variant="outline-secondary"
									class="btn-icon mt-1 cursor-pointer"
									size="sm"
									@click="openSkipTo(field)"
								>
									<feather-icon icon="CornerDownLeftIcon" size="16" />
								</b-button>
							</div>
						</div>
					</div>
				</b-card>
			</orderable-list>
		</template>
		<div v-else class="text-muted text-center mt-3">
			<feather-icon icon="AlertCircleIcon" size="48" />
			<p class="mb-0 mt-1">
				You have not added any fields yet
			</p>
		</div>
		<confirm-dailog ref="confirm"></confirm-dailog>
		<skip-to v-if="skipField" v-model="skipToOption" :field-data="skipField" @ok="skipTo"></skip-to>
		<edit-field v-if="currentField" :field-data="currentField" :form-id="pageData.formId" :page-id="pageData.page.id"></edit-field>
	</div>
</template>

<script>
import store from '@/store';
import { BCard, BButton, VBTooltip, VBModal } from 'bootstrap-vue';
import ConfirmDailog from '@/components/ConfirmDailog.vue';
import OrderableList from '@/components/OrderableList.vue';
import FieldPreview from './preview/field-preview.vue';
import SkipTo from './skip-to.vue';
import EditField from './edit-field.vue';

export default {
	name: 'PageFields',

	components: {
		BCard,
		BButton,

		ConfirmDailog,
		SkipTo,
		EditField,
		FieldPreview,
		OrderableList,
	},

	directives: {
		'b-tooltip': VBTooltip,
		'b-modal': VBModal,
	},

	props: {
		pageData: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		currentField: undefined,
		skipField: undefined,
		skipToOption: '',
	}),

	methods: {
		canDeleteField(filed) {
			// check if the filed is new
			if (typeof filed.id === 'string' && filed.id.startsWith('_')) {
				return true;
			}

			return !this.pageData.form_is_locked;
		},

		editField(field) {
			this.currentField = {
				...field,
				isValid: true,
			};
		},

		async deleteField(field) {
			const confirm = await this.$refs.confirm.open('Delete filed', 'Are you sure, delete this filed?');
			if (confirm) {
				await store.dispatch('forms/deleteField', { formId: this.pageData.formId, fieldId: field.id, fieldType: field.type });
			}
		},

		openSkipTo(field) {
			this.skipField = field;
		},

		addDynamic(field) {
			this.$emit('addDynamic', {
				isDynamic: true,
				id: field.id,
			});
		},

		skipTo(field) {
			this.$emit('skipTo', {
				fieldId: field.id,
				optionId: this.skipToOption,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.field-actions {
	border-left: 1px solid rgb(96 125 139 / 20%) !important;
}

.card.border-secondary {
	border: 1px solid rgb(96 125 139 / 20%) !important;
}
</style>
