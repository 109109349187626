<template>
	<div class="mb-1">
		<label class="">
			<div class="">
				<p class="mb-0">{{ fieldData.question }} <span v-show="fieldData.is_required" class="text-danger"> *</span></p>
			</div>
		</label>
		<b-input-group>
			<b-form-input v-model="value" type="text" placeholder="YYYY-MM-DD" autocomplete="off" show-decade-nav />
			<b-input-group-append>
				<b-form-datepicker v-model="value" show-decade-nav button-only right locale="en-US" @context="onContext" />
			</b-input-group-append>
		</b-input-group>
		<small v-show="fieldData.help">{{ fieldData.help }}</small>
	</div>
</template>

<script>
import { BInputGroup, BInputGroupAppend, BFormInput, BFormDatepicker } from 'bootstrap-vue';

export default {
	name: 'TextFieldPreview',

	components: {
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		BFormDatepicker,
	},

	props: {
		fieldData: {
			type: Object,
			default: () => ({
				question: '',
				is_required: false,
				help: '',
			}),
		},
	},

	data: () => ({
		value: '',
		formatted: '',
		selected: '',
	}),

	methods: {
		onContext(ctx) {
			this.formatted = ctx.selectedFormatted;
			this.selected = ctx.selectedYMD;
		},
	},
};
</script>

<style lang="scss"></style>
