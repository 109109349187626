<template>
	<div>
		<b-modal
			id="edit-field-modal"
			ref="editFieldModal"
			title="Edit field"
			ok-title="Save"
			ok-only
			:ok-disabled="!fieldData.isValid"
			size="lg"
			centered
			@ok="handleOk"
		>
			<div>
				<b-card-text>
					<component :is="fieldData.type" v-model="fieldData" />
				</b-card-text>
			</div>
		</b-modal>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import store from '@/store';

import { BButton, BCard, BCardText, BModal, VBModal } from 'bootstrap-vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import TextField from './fields/text-field.vue';
import LargeTextField from './fields/large-text-field.vue';
import ListField from './fields/list-field.vue';
import TableListField from './fields/table-list-field.vue';
import DateField from './fields/date-field.vue';
import ImageField from './fields/image-field.vue';
import DynamicField from './fields/dynamic-field.vue';

export default {
	name: 'EditField',

	components: {
		BButton,
		BCard,
		BCardText,
		BModal,
		FeatherIcon,

		TextField,
		LargeTextField,
		ListField,
		TableListField,
		DateField,
		ImageField,
		DynamicField,
	},

	directives: {
		'b-modal': VBModal,
	},

	props: {
		pageId: {
			type: [String, Number],
			require: true,
		},
		fieldData: {
			type: Object,
			required: true,
		},
	},

	data: () => ({}),

	methods: {
		handleOk() {
			delete this.fieldData.isValid;
			store.dispatch('forms/editField', {
				fieldData: this.fieldData,
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>
