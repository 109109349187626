<template>
	<div>
		<b-form-group>
			<template #label>
				<div class="">
					<p class="mb-0">{{ fieldData.question }} <span v-show="fieldData.is_required" class="text-danger"> *</span></p>
				</div>
			</template>
			<div class="input-group">
				<span v-show="fieldData.prefix" class="input-group-text left">{{ fieldData.prefix }}</span>
				<b-form-input id="question" :type="fieldData.only_numbers ? 'number' : 'text'" />
				<span v-show="fieldData.suffix" class="input-group-text right"> {{ fieldData.suffix }} </span>
			</div>
			<small v-show="fieldData.help">{{ fieldData.help }}</small>
		</b-form-group>
	</div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
	name: 'TextFieldPreview',

	components: {
		BFormGroup,
		BFormInput,
	},

	props: {
		fieldData: {
			type: Object,
			default: () => ({
				question: '',
				prefix: '',
				suffix: '',
				is_required: false,
				only_numbers: false,
				help: '',
			}),
		},
	},
};
</script>

<style lang="scss" scoped>
.input-group-text.left {
	border-radius: 0;
	border-right: none;
}

.input-group-text.right {
	border-radius: 0;
	border-left: none;
}
</style>
