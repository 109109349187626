<template>
	<div>
		<b-form-group>
			<template #label>
				<div class="">
					<p class="mb-0">{{ fieldData.question }} <span v-show="fieldData.is_required" class="text-danger"> *</span></p>
				</div>
			</template>
		</b-form-group>
		<div class="mt-2">
			<template v-if="fieldData.form.pages[0].fields.length">
				<orderable-list v-model="fieldData.form.pages[0].fields">
					<b-card v-for="field in fieldData.form.pages[0].fields" :key="field.id" border-variant="secondary" class="shadow-none" no-body>
						<div class="d-flex">
							<div class="p-1 flex-grow-1">
								<component :is="`${field.type}Preview`" :field-data="field" />
							</div>
							<div v-can="'formsBuilder.edit'" class="p-50 d-flex  align-items-center field-actions">
								<div class="d-flex flex-column">
									<b-button variant="flat-secondary" class="btn-icon mb-1" size="sm">
										<feather-icon icon="MenuIcon" class="drag-handle" />
									</b-button>

									<b-button variant="flat-secondary" class="btn-icon cursor-pointer mb-1" size="sm" @click="deleteField(field)">
										<feather-icon icon="Trash2Icon" size="16" />
									</b-button>

									<b-button
										v-b-modal.edit-field-modal
										variant="flat-secondary"
										class="btn-icon cursor-pointer mb-1"
										size="sm"
										@click="editField(field)"
									>
										<feather-icon icon="Edit3Icon" size="16" />
									</b-button>
								</div>
							</div>
						</div>
					</b-card>
				</orderable-list>
			</template>
			<div v-else class="text-muted text-center mt-3">
				<feather-icon icon="AlertCircleIcon" size="36" />
				<p class="mb-0 mt-1">
					You have not added any fields yet
				</p>
			</div>
		</div>
		<confirm-dailog ref="confirm"></confirm-dailog>
	</div>
</template>

<script>
import store from '@/store';
import { BFormGroup, BCard, BButton, VBModal, VBTooltip } from 'bootstrap-vue';
import ConfirmDailog from '@/components/ConfirmDailog';
import OrderableList from '@/components/OrderableList.vue';

/* eslint-disable vue/no-unused-components */
import TextFieldPreview from './text-field-preview';
import LargeTextFieldPreview from './large-text-field-preview';
import DateFieldPreview from './date-field-preview';
import ImageFieldPreview from './image-field-preview';

export default {
	name: 'DynamicFieldPreview',

	components: {
		BFormGroup,
		BCard,
		BButton,

		TextFieldPreview,
		LargeTextFieldPreview,
		DateFieldPreview,
		ImageFieldPreview,
		ListFieldPreview: () => import('./list-field-preview'),
		TableListFieldPreview: () => import('./table-list-field-preview'),

		ConfirmDailog,
		OrderableList,
	},

	directives: {
		'b-tooltip': VBTooltip,
		'b-modal': VBModal,
	},

	props: {
		fieldData: {
			type: Object,
			default: () => ({
				question: '',
				is_required: false,
				form: {
					name: '',
					description: '',
					pages: [
						{
							name: '',
							description: '',
							fields: [],
						},
					],
				},
			}),
		},
	},

	computed: {
		fields() {
			return this.fieldData.form.pages[0].fields;
		},
	},

	methods: {
		async deleteField(field) {
			const confirm = await this.$refs.confirm.open('Delete filed', 'Are you sure, delete this filed?');
			if (confirm) {
				await store.dispatch('forms/deleteField', { fieldId: field.id, fieldType: field.type, parentFieldId: this.fieldData.id });
			}
		},

		editField(field) {
			this.$emit('editfield', field);
		},
	},
};
</script>

<style lang="scss" scoped></style>
