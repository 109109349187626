<template>
	<div>
		<dynamic-field-preview
			v-if="fieldData.type === 'DynamicField'"
			:field-data="fieldData"
			@editfield="$emit('editfield', $event)"
		></dynamic-field-preview>
		<list-field-preview
			v-else-if="fieldData.type === 'ListField'"
			:field-data="fieldData"
			:show-extra="showExtra"
			@editfield="$emit('editfield', $event)"
		></list-field-preview>
		<component :is="previewComponent" v-else :field-data="fieldData" />
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import TextFieldPreview from './text-field-preview';
import LargeTextFieldPreview from './large-text-field-preview';
import DateFieldPreview from './date-field-preview';
import ImageFieldPreview from './image-field-preview';
import ListFieldPreview from './list-field-preview';
import TableListFieldPreview from './table-list-field-preview';
import DynamicFieldPreview from './dynamic-field-preview';

export default {
	name: 'FieldPreview',

	components: {
		TextFieldPreview,
		LargeTextFieldPreview,
		DateFieldPreview,
		ImageFieldPreview,
		ListFieldPreview,
		TableListFieldPreview,
		DynamicFieldPreview,
	},

	props: {
		fieldData: {
			type: Object,
			required: true,
		},
		showExtra: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		previewComponent() {
			return `${this.fieldData.type}Preview`;
		},
	},
};
</script>

<style lang="scss" scoped></style>
