<template>
	<div>
		<validation-observer ref="simpleRules">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Question" label-for="question">
							<validation-provider #default="{ errors }" name="Question" rules="required">
								<b-form-input
									id="question"
									v-model="formData.question"
									:state="errors.length > 0 ? false : null"
									placeholder="Question"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<b-form-checkbox v-model="formData.is_required" class="mb-1">Make this field required.</b-form-checkbox>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import { generateId } from '@/utils/helpers.js';
import FieldMixin from './field-mixin.js';

export default {
	name: 'DynamicField',

	mixins: [FieldMixin],

	props: {
		value: {
			type: Object,
			default: () => ({
				question: '',
				is_required: true,
				help: '',
				form: {
					id: generateId(),
					name: '',
					description: null,
					order: 1,
					pages: [
						{
							id: generateId(),
							name: '',
							description: null,
							fields: [],
						},
					],
				},
			}),
		},
	},

	watch: {
		value: {
			handler(val) {
				this.value.form.name = val.question;
				this.value.form.pages[0].name = val.question;
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped></style>
