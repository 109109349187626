<template>
	<draggable
		v-model="list"
		class="list-group"
		tag="ul"
		handle=".drag-handle"
		v-bind="dragOptions"
		:move="onMove"
		@start="drag = true"
		@end="drag = false"
	>
		<transition-group type="transition" :name="!drag ? 'flip-list' : null" tag="div" :class="{ 'parent-list': isParent }">
			<slot />
		</transition-group>
	</draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
	name: 'OrderableList',

	components: {
		draggable,
	},

	props: {
		value: {
			type: Array,
			required: true,
		},

		isParent: Boolean,
	},

	data: () => ({
		drag: false,
		list: [],
	}),

	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			};
		},
	},

	watch: {
		list: {
			handler(val) {
				const newList = val.map((item, i) => ({ ...item, order: i + 1 }));
				this.$emit('input', newList);
			},
			deep: true,
		},
	},

	created() {
		this.list = this.value;
	},

	methods: {
		onMove(event) {
			const toParent = event.to.classList.contains('parent-list');
			const fromParent = event.from.classList.contains('parent-list');
			const inTheSameList = (toParent && fromParent) || (!toParent && !fromParent);

			return inTheSameList;
		},
	},
};
</script>

<style lang="scss" scoped>
.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.ghost {
	opacity: 0.7;
	background-color: #f3f3f3;
}
.list-group {
	min-height: 20px;
}
</style>
