<template>
	<div>
		<b-form-group>
			<template #label>
				<div class="">
					<p class="mb-0">{{ fieldData.question }} <span v-show="fieldData.is_required" class="text-danger"> *</span></p>
				</div>
			</template>
			<b-form-textarea id="question" :type="fieldData.only_numbers ? 'number' : 'text'" />
			<small v-show="fieldData.help">{{ fieldData.help }}</small>
		</b-form-group>
	</div>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue';

export default {
	name: 'LargeTextFieldPreview',

	components: {
		BFormGroup,
		BFormTextarea,
	},

	props: {
		fieldData: {
			type: Object,
			default: () => ({
				question: '',
				max: '',
				min: '',
				is_required: false,
				help: '',
			}),
		},
	},
};
</script>

<style lang="scss" scoped></style>
