<template>
	<div>
		<validation-observer ref="simpleRules">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Question" label-for="question">
							<validation-provider #default="{ errors }" name="Question" rules="required">
								<b-form-input
									id="question"
									v-model="formData.question"
									:state="errors.length > 0 ? false : null"
									placeholder="Question"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-group label="Prefix" label-for="prefix">
							<b-form-input id="prefix" v-model="formData.prefix" type="text" placeholder="Prefix" />
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-group label="Suffix" label-for="suffix">
							<b-form-input id="suffix" v-model="formData.suffix" type="text" placeholder="Suffix" />
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<b-form-group label="Help" label-for="help">
							<b-form-textarea id="help" v-model="formData.help" type="text" placeholder="Help" />
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<b-form-checkbox v-model="formData.is_required" class="mb-1">Make this field required.</b-form-checkbox>
						<b-form-checkbox v-model="formData.only_numbers">Only allow numbers to be entered to this field.</b-form-checkbox>
					</b-col>

					<b-col>
						<hr class="mt-3 mb-50" />
						<span class="h5">Preview</span>
					</b-col>

					<b-col cols="12" class="mt-2">
						<text-field-preview :field-data="value"></text-field-preview>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import FieldMixin from './field-mixin.js';
import TextFieldPreview from '../preview/text-field-preview.vue';

export default {
	name: 'TextField',

	components: {
		TextFieldPreview,
	},

	mixins: [FieldMixin],

	props: {
		value: {
			type: Object,
			default: () => ({
				question: '',
				prefix: '',
				suffix: '',
				is_required: true,
				only_numbers: false,
				help: '',
				isValid: false,
			}),
		},
	},
};
</script>

<style lang="scss" scoped></style>
