<template>
	<b-modal id="skip-to-modal" centered title="Skip to question" @ok="handleOk" @show="handleShow">
		<div class="skip-item">
			<b-form-group label="Select option">
				<v-select
					id="type"
					v-model="option"
					:clearable="false"
					:filterable="false"
					:searchable="false"
					:options="optionList"
					:reduce="(o) => o.id"
				/>
			</b-form-group>
		</div>
		<template #modal-footer="{ ok, close }">
			<b-button variant="outline-secondary" @click="close">Cancel</b-button>
			<b-button v-b-modal.new-field-modal variant="primary" :disabled="!option" @click="ok">Next</b-button>
		</template>
	</b-modal>
</template>

<script>
import { BModal, BFormGroup, BButton, VBModal } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
	name: 'SkipTo',

	components: {
		BModal,
		BFormGroup,
		vSelect,
		BButton,
	},

	directives: {
		'v-modal': VBModal,
	},

	props: {
		fieldData: {
			type: Object,
			required: true,
		},

		value: {
			type: [Number, String],
			required: true,
		},
	},

	data: () => ({
		option: '',
		question: '',
	}),

	computed: {
		optionList() {
			return this.fieldData.options.map((o) => ({ id: o.id, label: o.answer }));
		},
	},

	methods: {
		handleOk() {
			this.$emit('input', this.option);
			this.$emit('ok', this.fieldData);
		},

		handleShow() {
			this.option = '';
		},
	},
};
</script>

<style lang="scss" scoped></style>
