<template>
	<div>
		<validation-observer ref="simpleRules">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Question" label-for="question">
							<validation-provider #default="{ errors }" name="Question" rules="required">
								<b-form-input
									id="question"
									v-model="formData.question"
									:state="errors.length > 0 ? false : null"
									placeholder="Question"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-1">
						<label class="mb-50">Options will be</label>
						<b-form-radio-group v-model="formData.options_are" :options="optionTypes" stacked value-field="id" text-field="label" />
					</b-col>

					<b-col cols="12">
						<b-form-group label="Help" label-for="help">
							<b-form-textarea id="help" v-model="formData.help" type="text" placeholder="Help" />
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-checkbox v-model="formData.is_required" class="mb-1">Make this field required</b-form-checkbox>
						<b-form-checkbox v-model="formData.is_multiple" :disabled="formData.is_dropdown">Allow multiple selection</b-form-checkbox>
					</b-col>
					<b-col cols="6">
						<b-form-checkbox v-model="formData.is_dropdown" class="mb-1">Show as drop down list</b-form-checkbox>
						<b-form-checkbox v-model="formData.allow_other">Allow select other</b-form-checkbox>
					</b-col>

					<b-col>
						<hr class="mt-3 mb-50" />
						<span class="h5">Preview</span>
					</b-col>

					<b-col cols="12" class="mt-2">
						<table-list-field-preview :field-data="value"></table-list-field-preview>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import { BFormRadioGroup, BFormCheckbox, VBTooltip } from 'bootstrap-vue';
import FieldMixin from './field-mixin.js';
import TableListFieldPreview from '../preview/table-list-field-preview.vue';

export default {
	name: 'TableListField',

	components: {
		BFormRadioGroup,
		BFormCheckbox,
		TableListFieldPreview,
	},

	directives: {
		'b-tooltip': VBTooltip,
	},

	mixins: [FieldMixin],

	props: {
		value: {
			type: Object,
			default: () => ({
				question: '',
				help: '',
				is_required: true,
				is_multiple: false,
				is_dropdown: true,
				allow_other: false,
				options_are: 1,
			}),
		},
	},

	data: () => ({
		optionTypes: [
			{
				label: 'Project items',
				id: 1,
			},
			{
				label: 'Project beneficiaries',
				id: 2,
			},
		],
	}),

	watch: {
		value: {
			handler(val) {
				if (val.is_dropdown) {
					this.value.is_multiple = false;
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.custom-checkbox.custom-control,
.custom-radio.custom-control {
	margin-bottom: 0;
}
</style>
