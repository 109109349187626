<template>
	<div>
		<validation-observer ref="simpleRules">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Question" label-for="question">
							<validation-provider #default="{ errors }" name="Question" rules="required">
								<b-form-input
									id="question"
									v-model="formData.question"
									:state="errors.length > 0 ? false : null"
									placeholder="Question"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-group label="Min" label-for="min">
							<b-form-input id="min" v-model="formData.min" type="number" placeholder="Min" />
						</b-form-group>
					</b-col>

					<b-col cols="6">
						<b-form-group label="Max" label-for="max">
							<b-form-input id="max" v-model="formData.max" type="number" placeholder="Max" />
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<b-form-group label="Help" label-for="help">
							<b-form-textarea id="help" v-model="formData.help" type="text" placeholder="Help" />
						</b-form-group>
					</b-col>

					<b-col cols="12">
						<b-form-checkbox v-model="formData.is_required" class="mb-1">Make this field required.</b-form-checkbox>
					</b-col>

					<b-col>
						<hr class="mt-3 mb-50" />
						<span class="h5">Preview</span>
					</b-col>

					<b-col cols="12" class="mt-2">
						<image-field-preview :field-data="value"></image-field-preview>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import FieldMixin from './field-mixin.js';
import ImageFieldPreview from '../preview/image-field-preview.vue';

export default {
	name: 'ImageField',

	components: {
		ImageFieldPreview,
	},

	mixins: [FieldMixin],

	props: {
		value: {
			type: Object,
			default: () => ({
				question: '',
				min: null,
				max: null,
				is_required: true,
				help: '',
			}),
		},
	},
};
</script>

<style lang="scss" scoped></style>
