<template>
	<div>
		<b-button
			v-b-modal.new-field-modal
			v-b-tooltip.hover.left="`Add field`"
			variant="primary"
			class="btn-icon rounded-circle new-field-btn"
			size="lg"
		>
			<feather-icon icon="PlusIcon" size="20" />
		</b-button>
		<b-modal
			id="new-field-modal"
			ref="newFieldModal"
			:title="modalTitle"
			:ok-title="okBtnText"
			cancel-title="Prev"
			:cancel-disabled="step === 1"
			:ok-disabled="isOkDisabled"
			size="lg"
			centered
			cancel-variant="outline-secondary"
			@show="reset"
			@ok="handleOk"
			@cancel="handleCancel"
			@hidden="$emit('hidden')"
		>
			<div v-show="step === 1" class="">
				<div class="d-flex flex-wrap">
					<b-card
						v-for="type in allTypes"
						:key="type.key"
						:border-variant="currentType === type.key ? 'primary' : ''"
						class="mb-2 mx-50 cursor-pointer text-center"
						style="width: 31%"
						@click="currentType = type.key"
					>
						<feather-icon :icon="type.icon" size="32" class="mb-1"></feather-icon>
						<b-card-text>
							{{ type.name }}
						</b-card-text>
					</b-card>
				</div>
			</div>
			<div v-show="step === 2" class="">
				<b-card-text>
					<component :is="currentType" v-model="fieldData" />
				</b-card-text>
			</div>
		</b-modal>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BButton, BCard, BCardText, BModal, VBModal, VBTooltip } from 'bootstrap-vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import TextField from './fields/text-field.vue';
import LargeTextField from './fields/large-text-field.vue';
import ListField from './fields/list-field.vue';
import TableListField from './fields/table-list-field.vue';
import DateField from './fields/date-field.vue';
import ImageField from './fields/image-field.vue';
import DynamicField from './fields/dynamic-field.vue';

export default {
	name: 'NewField',

	components: {
		BButton,
		BCard,
		BCardText,
		BModal,
		FeatherIcon,

		TextField,
		LargeTextField,
		ListField,
		TableListField,
		DateField,
		ImageField,
		DynamicField
	},

	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip
	},

	props: {
		pageId: {
			type: [String, Number],
			require: true
		},
		isDynamic: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		tabIndex: 0,
		step: 1,

		fieldData: undefined,

		currentType: '',
		fieldTypes: [
			{ key: 'TextField', name: 'Text field', icon: 'FileIcon' },
			{ key: 'LargeTextField', name: 'Large text field', icon: 'FileIcon' },
			{ key: 'ListField', name: 'List field', icon: 'FileIcon' },
			{ key: 'TableListField', name: 'Table List field', icon: 'FileIcon' },
			{ key: 'DateField', name: 'Date field', icon: 'FileIcon' },
			{ key: 'ImageField', name: 'Image field', icon: 'FileIcon' },
			{ key: 'DynamicField', name: 'Dynamic field', icon: 'FileIcon' }
		]
	}),

	computed: {
		okBtnText() {
			return this.step === 1 ? 'Next' : 'Save';
		},
		isOkDisabled() {
			if (this.currentType === '') return true;
			return this.step === 2 ? !this.fieldData?.isValid : false;
		},
		modalTitle() {
			if (this.step === 1) return 'Select field type';
			if (this.step === 2) return this.fieldTypes.find((type) => type.key === this.currentType).name;
			return 'Field details';
		},
		allTypes() {
			return this.isDynamic ? this.fieldTypes.filter((type) => type.key !== 'DynamicField') : this.fieldTypes;
		}
	},

	methods: {
		handleOk(bvModalEvt) {
			if (this.step === 1) {
				this.step = 2;
				// Prevent modal from closing
				bvModalEvt.preventDefault();
				return;
			}

			delete this.fieldData.isValid;

			this.$emit('submitted', {
				fieldData: {
					type: this.currentType,
					...this.fieldData
				},
				pageId: this.pageId
			});
		},

		handleCancel(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();

			if (this.step === 2) this.reset();
		},

		reset() {
			this.step = 1;
			this.currentType = '';
			this.fieldData = undefined;
		}
	}
};
</script>

<style lang="scss" scoped>
.new-field-btn {
	position: fixed;
	bottom: 3%;
	right: 2%;
	z-index: 999;
}
</style>
